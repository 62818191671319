var jq = jQuery.noConflict(true);
var imageUl = '../';
var popupViewer = false;
var global = global || window;//open this if you take global error
var Buffer = Buffer || [];//open this if you take buffer error
var process = process || {
    env: { DEBUG: undefined },
    version: []
};

 function myFunction () {
    $("#dialogModal").hide();
}